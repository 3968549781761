import { PreviewAnyFile } from '@ionic-native/preview-any-file/ngx';
import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { PopoverController, Platform } from '@ionic/angular';
import { InsuranceService } from 'src/app/services/insurance.service';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-terms-seguros',
  templateUrl: './terms-seguros.component.html',
  styleUrls: ['./terms-seguros.component.scss'],
})
export class TermsSegurosComponent implements OnInit {
  terms: boolean = false;
  error: boolean = true;
  insurence:any;
  @Input() data:any;
  email: any;
    formData  = new FormData();
    poicies: boolean = false;
    doc: any;
    

  constructor(private popoverCtrl: PopoverController,private insuranceService:InsuranceService, public router: Router,
    private platform: Platform,private previewFile: PreviewAnyFile,private alertService:AlertService) { }

  ngOnInit() {}

  changeBackground(checked) {
    if (checked) {
      this.terms = true;
    } else {
      this.terms = false;
    }
  }
   async closePopup(policies){
    policies = this.terms;
    if (policies == false) {
      this.error = false;
    }
      if(this.data.hasInsurance == false){
        let nextUrl = '';
        if(this.data.product == 'family'){
          nextUrl = '/assist-secure/family';
        }else if(this.data.product == 'beneficiary'){
          nextUrl = '/assist-secure/beneficiary';
        }
        if(this.data.high_employee.email == ""){
  
          this.email = this.data['high_employee']['name'] + this.data['high_employee']['paternal_last_name'] + this.data['high_employee']['mother_last_name']+'@atmancapitalapp.com.mx';
          console.log(this.email);
          
          let data = {
            email: this.email
          }
          this.alertService.presentLoading()
          this.insuranceService.membreship(data).subscribe(resp =>{
              console.log(resp);
              this.alertService.dismissLoading()
              if(resp['status'] == 200){
               this.alertService.presentToast(resp['successMessage'])
               this.popoverCtrl.dismiss('create');
              }else if(resp['status'] == 500){
                this.alertService.presentToastError(resp['errorMessage'])
                this.popoverCtrl.dismiss();
              }
            
            },(err)=>{
              console.log('err',err)
              this.popoverCtrl.dismiss();
              this.alertService.dismissLoading()
            });
        }else {
          this.email = this.data.high_employee.email;
          console.log(this.email);
          let data = {
            email: this.email
          }
          this.alertService.presentLoading()
          this.insuranceService.membreship(data).subscribe(resp =>{
              console.log(resp);
              this.alertService.dismissLoading()
              if(resp['status'] == 200){
                this.alertService.presentToast(resp['successMessage'])
                this.popoverCtrl.dismiss('create');
               }else if(resp['status'] == 500){
                 this.alertService.presentToastError(resp['errorMessage'])
                 this.popoverCtrl.dismiss();
               }
              
            },(err)=>{
              console.log('err',err)
              this.popoverCtrl.dismiss();
              this.alertService.dismissLoading()
            });
        }
      
      }
     /* else{
        this.poicies = this.data.high_employee.insurance.id;
        this.insuranceService.Policiesdelet(this.poicies).subscribe(
          resp =>{
          
            console.log(resp);
          },(err)=>{
            console.log('err',err)
           
          });
    this.doc = this.data['membership']['url_poliza'];
    if(this.platform.is('desktop') || this.platform.is('mobileweb')){
      window.open(this.doc, '_system');
    } else {
      this.previewFile.preview(this.doc)
        .then((res: any) => console.log(res))
        .catch((error: any) => console.error(error));
    }

      }*/

    
  }
  cencelPopup(){
    this.popoverCtrl.dismiss();
  this.router.navigate(["/dashboard"]);    
  }
}
