import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-domiciliation',
  templateUrl: './domiciliation.component.html',
  styleUrls: ['./domiciliation.component.scss'],
  providers: [DatePipe]
})
export class DomiciliationComponent implements OnInit {

  @Input() signature_url: string;

  @Input() current_first_transaction_date: string;

  today: Date = new Date();

  user: any;

  maxAmount: any;

  constructor(
    public modalController : ModalController, 
    private authService:AuthService
  ) { }

  ngOnInit() {
    this.authService.user().subscribe(
      (data:any)=>{
        this.user = data;
        const amountReplace = data.high_employee.max_amount.replace(/,/g, '');
        this.maxAmount = parseFloat(amountReplace) + 100;
        console.log(this.maxAmount);
      }
    );
  }

  closeModal(){
    this.modalController.dismiss();
  }
}
