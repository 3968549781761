import { Component, OnInit } from '@angular/core';

import { Platform, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthService } from './services/auth.service';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { PushService } from './services/push.service';
import { Badge } from '@ionic-native/badge/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { Smartlook, SmartlookSetupConfig } from '@ionic-native/smartlook/ngx';
import { environment } from '../environments/environment';
import {FCM} from 'cordova-plugin-fcm-with-dependecy-updated-12/ionic/ngx';
declare let window: any;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public appPages = [
    {
      title: 'Inbox',
      url: '/folder/Inbox',
      icon: 'mail'
    },
    {
      title: 'Outbox',
      url: '/folder/Outbox',
      icon: 'paper-plane'
    },
    {
      title: 'Favorites',
      url: '/folder/Favorites',
      icon: 'heart'
    },
    {
      title: 'Archived',
      url: '/folder/Archived',
      icon: 'archive'
    },
    {
      title: 'Trash',
      url: '/folder/Trash',
      icon: 'trash'
    },
    {
      title: 'Spam',
      url: '/folder/Spam',
      icon: 'warning'
    }
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  heigth: any = 0;

  constructor(
    private toastController:ToastController,
    private fcm: FCM,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authService: AuthService,
    private keyboard: Keyboard,
    private pushService:PushService,
    private badge: Badge,
    private oneSignal: OneSignal,
    private smartlook: Smartlook
  ) {
    this.initializeApp();
    this.platform.ready().then(() =>{
      this.smartlook.setupAndStartRecording(new SmartlookSetupConfig(environment.SMARTLOOK_KEY));
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {


      //
      if(window.MobileAccessibility){
        window.MobileAccessibility.usePreferredTextZoom(false);
      }

      this.statusBar.styleLightContent();

      if (this.platform.is('android')) {
        this.platform.keyboardDidShow.subscribe(ev => {
          const { keyboardHeight } = ev;
          // Do something with the keyboard height such as translating an input above the keyboard.
          this.heigth = keyboardHeight;
          const activeEle: any = document.activeElement;
          if (activeEle.type === 'textarea') {
            activeEle.scrollIntoView();
          }
        });
        this.platform.keyboardDidHide.subscribe(() => {
          // Move input back to original location
          this.heigth = 0;
        });
        this.keyboard.hideFormAccessoryBar(false);
      } else {
        this.statusBar.styleDefault();
      }

      this.pushService.initConfig();

      this.platform.pause.subscribe(() => {
        console.log('****UserdashboardPage PAUSED****');
        //this.pushService.initConfig();
        this.platform.pause.unsubscribe();
      });

      this.platform.resume.subscribe(() => {
        console.log('****UserdashboardPage RESUMED****');
        // calling service
        this.platform.resume.unsubscribe();
      });

      this.splashScreen.hide();
    });
  }

  ionViewWillLeave() {
    this.authService.imageUser = null;
  }
  async showNotification(msg){
    const toast = await this.toastController.create({
      message: msg.body ?  msg.body : msg.msg,
      duration: 3000,
      position: 'bottom',
      mode: 'md'
    });
    toast.present();
  }
  ngOnInit() {
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }

    // document.documentElement.style.setProperty('--ion-color-one', '#B52339');
    // document.documentElement.style.setProperty('--ion-color-two', '#CE1F3C');
    // document.documentElement.style.setProperty('--ion-color-three', '#fdeeaf');
    //document.documentElement.style.setProperty('--ion-color-four', '#5176F3');

    //this.colorService.one = 'var(--ion-color-one)';
    //this.colorService.two = '#5176F3';

    //this.colorService.ilustrationOne = '#D4EACC';
    //this.colorService.illustrationTwo = '#058639';
    //document.documentElement.style.setProperty('--ion-color-three', 'red');
    //document.documentElement.style.setProperty('--ion-color-four', 'red');
  }
}
