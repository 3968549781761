import { Injectable } from '@angular/core';
import { ToastController, LoadingController } from '@ionic/angular';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Storage } from '@ionic/storage';
import { environment } from '../../environments/environment';
import { retry, catchError, tap, shareReplay } from 'rxjs/operators';
import { User } from '../models/user';
import { AppStorageKey, StatusHttp } from '../static';
import { AuthTokenObject } from '../models';
import { HTTP } from '@ionic-native/http/ngx';

// TODO: Move to a folder with the other interfaces.
type LoginResponse = {
  expires_at: string;
  status: number;
  successMessage: string;
  token: string;
  token_type:  string;
  login_type: string;
  errorMessage?: string;
  app_download?:string;
}

type HttpResponseLike = {
  status?: number;
  data?: any;
}




@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isLoggedIn = false;
  token: AuthTokenObject;

  isLoading: boolean = false;

  imageUser:any;

  constructor(
    private http: HttpClient,
    private storage: Storage,
    private toastController:ToastController,
    private nativeHTTP: HTTP,
    public loadingCtrl: LoadingController,
  
  ) {

  }

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache'
    })
  }

  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };

  getToken() {
    return this.storage.get(AppStorageKey.AuthTokenObject).then(
      data => {
        this.token = data;
        if(this.token != null) {
          this.isLoggedIn=true;
        } else {
          this.isLoggedIn=false;
        }
      },
      error => {
        this.token = null;
        this.isLoggedIn=false;
      }
    );
  }

  //Obtener datos de usuario sin logueo
  userName(data) {
    return this.http
      .post(environment.API_URL+'/user_name', JSON.stringify(data), this.httpOptions)
      .pipe(
        tap(user => {
          return user;
        }),
        catchError(this.handleError)
      )
  }

  //Obtener usuario logueado
  user() {
    return this.http.post<User>(environment.API_URL + '/user', this.httpOptions)
    .pipe(
      tap(user => {
        return user;
      })
    )
  }

  userNewPassword(data:any) {
    return this.http.post<any>(environment.API_URL + '/update_configuration_password', data,this.httpOptions);
  }

  //Obtener saldo maximo del usuario
  getAmount() {
    return this.http
      .post(environment.API_URL+'/get_amount', JSON.stringify({}),  this.httpOptions)
      .pipe(
        tap(amount => {
          return amount;
        }),
        catchError(this.handleError)
      )
  }

  //Obtener saldo maximo del usuario
  getDashboardData() {
    return this.http
      .get(environment.API_URL+'/get_dashboard_data')
      .pipe(
        tap(response => {
          return response;
        }),
        catchError(this.handleError)
      )
  }

  //Obtener saldo maximo del usuario
  getProducts() {
    return this.http
      .get(environment.API_URL+'/get_products')
      .pipe(
        tap(products => {
          return products;
        }),
        catchError(this.handleError)
      )
  }

  //Obtener saldo maximo del usuario
  getUnattended() {
    return this.http
      .get(environment.API_URL+'/get_unattended')
      .pipe(
        tap(response => {
          return response;
        }),
        catchError(this.handleError)
      )
  }
     

  //  Returns a boolean value referred to the current state of the token provided.
  updateAuthStateIfTokenProvided(): Promise<boolean> {
    return new Promise(async (res) => {
      const isTokenStored: AuthTokenObject = await this.storage.get(AppStorageKey.AuthTokenObject);
      if (isTokenStored !== null) {
        this.token = isTokenStored;
        this.isLoggedIn = true;
        res(true);
      } else {
        res(false);
      }
    });
  }

  // Logueo
  login(credentials): Observable<LoginResponse> {
    return this.http
      .post(environment.API_URL+'/login', JSON.stringify(credentials), this.httpOptions)
      .pipe(
        tap((response: LoginResponse) => {
          if (response.status === StatusHttp.Success) {
            const newAuthToken: AuthTokenObject = {
              token: response.token,
              token_type: response.token_type,
              expires_at: response.expires_at,
              login_type: response.login_type
            };
            this.token = newAuthToken;
            this.storage.set(AppStorageKey.AuthTokenObject, newAuthToken).then(
              (token) => console.log('Auth Token Stored')
            ).catch(err => {
              this.presentToast('Error al guardar el token de logueo: '+JSON.stringify(err),'danger');
            });
            
            this.isLoggedIn = true;
          }
          return response;
        }),
        catchError(this.handleError)
      )
  }

  // Logueo
  registro(credentials) {
    return this.http
      .post(environment.API_URL+'/signup', JSON.stringify(credentials), this.httpOptions)
      .pipe(
        tap((token: AuthTokenObject) => {
          this.storage.set(AppStorageKey.AuthTokenObject, token).then(
            (token) => console.log('Auth Token Stored')
          ).catch(err => {
            this.presentToast('Error al guardar el token de logueo: '+JSON.stringify(err),'danger');
          });
          this.token = token;
          this.isLoggedIn = true;
          return token;
        }),
        catchError(this.handleError)
      )
  }

  // Guardar token de One Signal
  saveOneSignalToken(data) {
    return this.http
      .post(environment.API_URL+'/save_notification_token', JSON.stringify(data), this.httpOptions)
      .pipe(
        tap(data => {
          return data;
        }),
        catchError(this.handleError)
      )
  }

  //Cerrar sesion
  async logout() {
    let OneSignalTokenStored = await this.storage.get(AppStorageKey.OneSignalUserToken);
    let data = { onesignal_token: OneSignalTokenStored  };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(environment.API_URL + '/logout',JSON.stringify(data), { headers: headers })
    .pipe(
      tap(data => {
        this.storage.remove(AppStorageKey.AuthTokenObject);
        this.isLoggedIn = false;
        delete this.token;
        return data;
      })
    )
  }

  deleteAccount() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(environment.API_URL + '/removeAccount',
      {}, {headers: headers})
      .pipe(
        tap(data => {
          this.storage.remove(AppStorageKey.AuthTokenObject);
          this.isLoggedIn = false;
          delete this.token;
          return data;
        })
      );
  }

  //Cerrar sesion
  async logoutDelete() {
    let data = {};
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(environment.API_URL + '/logout-delete',JSON.stringify(data), { headers: headers })
    .pipe(
      tap(data => {
        this.storage.remove(AppStorageKey.AuthTokenObject);
        this.isLoggedIn = false;
        delete this.token;
        return data;
      })
    )
  }

  //Envio de pin de transaccion de usuario
  sendTransactionPin(data){
    return this.http
      .post(environment.API_URL+'/set_transaction_code', JSON.stringify(data), this.httpOptions)
      .pipe(
        tap(response => {
          return response;
        }),
        catchError(this.handleError)
      )
  }

  //Envio de pin de transaccion para verificar usuario
  verifyPin(data){
    return this.http
      .post(environment.API_URL+'/verify_code', JSON.stringify(data), this.httpOptions)
      .pipe(
        tap(response => {
          return response;
        }),
        catchError(this.handleError),
        shareReplay(1)
      )
  }
  fingerprint(data){
    return this.http
      .post(environment.API_URL+'/fingerprint', JSON.stringify(data), this.httpOptions)
      .pipe(
        tap(response => {
          return response;
        }),
        catchError(this.handleError),
        shareReplay(1)
      )
  }

  changeCode(data){
    return this.http
      .post(environment.API_URL+'/change_code', data, this.httpOptions)
      .pipe(
        tap(response => {
          return response;
        }),
        catchError(this.handleError)
      )
  }


  //Envio de codigo de verificacion de usuario
  sendVerificationUser(data): Observable<HttpResponseLike>{
    return this.http
      .post(environment.API_URL+'/send_validation_code', JSON.stringify(data), this.httpOptions)
      .pipe(
        tap(response => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  //Envio de codigo de recuperacion de contraseña
  sendPasswordRecoverCode(data){
    return this.http
      .post(environment.API_URL+'/reset_password', JSON.stringify(data), this.httpOptions)
      .pipe(
        tap(response => {
          return response;
        }),
        catchError(this.handleError)
      )
  }

  //Envio de codigo de recuperacion de NIP
  async sendPinRecoverCode() {
    let TokenStored: AuthTokenObject = await this.storage.get(AppStorageKey.AuthTokenObject);
    return this.http
      .get(environment.API_URL+'/reset_pin/'+TokenStored.login_type)
      .pipe(
        tap(response => {
          return response;
        }),
        catchError(this.handleError)
      )
  }

  //Verificacion de codigo para recuperacion de NIP
  setPinRecoverCode(data){
    return this.http
      .post(environment.API_URL+'/set_recover_pin_code', JSON.stringify(data), this.httpOptions)
      .pipe(
        tap(response => {
          return response;
        }),
        catchError(this.handleError)
      )
  }

  //Enviar datos de cambio de contraseña
  changePassword(data){
    return this.http
      .post(environment.API_URL+'/change_password', JSON.stringify(data), this.httpOptions)
      .pipe(
        tap((response: AuthTokenObject) => {
          this.storage.set(AppStorageKey.AuthTokenObject, response).then(
            (token) => console.log('Auth Token Stored')
          ).catch(err => {
            this.presentToast('Error al guardar el token de logueo: '+JSON.stringify(err),'danger');
          });
          this.token = response;
          this.isLoggedIn = true;
          return response;
        }),
        catchError(this.handleError)
      )
  }

  //Verificacion de codigo introducido por usuario
  setVerificationUser(data){
    return this.http
      .post(environment.API_URL+data['route'], JSON.stringify(data), this.httpOptions)
      .pipe(
        tap(response => {
          return response;
        }),
        catchError(this.handleError)
      )
  }

  async subirImagen( img: string ) {

    let token_header = `${this.token.token_type} ${this.token.token}`;

    this.presentLoading();
    await this.nativeHTTP.uploadFile(
      environment.API_URL + '/change_profile_image',
      {},
      { Authorization: token_header },
      img,
      'photo'
    )
    .then(response => {
      // prints 200
      console.log("Response successfull status: "+response.status);
      console.log("Response successfull body: "+JSON.stringify(response));
      let data = JSON.parse(response.data);
      if(data['status'] == 200){
        this.dismissLoading();
        this.presentToast(data['successMessage'],'success');
        this.imageUser = data['url'];
      }else if(data['status'] == 500){
        this.dismissLoading();
        this.presentToast(data['errorMessage'],'danger');
      }
    })
    .catch(response => {
      // prints 403
      console.log("Response status: "+response.status);
      // prints Permission denied
      console.log("Response error: "+response.error);
      console.log('error en carga', JSON.stringify(response));
      this.dismissLoading();
      this.presentToast('File native upload error: '+JSON.stringify(response),'danger');
    });

  }

  async validateIneImage(formData) {
    this.nativeHTTP.setDataSerializer('multipart');
    const hasPreviousSession = await this.updateAuthStateIfTokenProvided();
    return await this.nativeHTTP.sendRequest(environment.API_URL + '/validate_ine_image',{
      method: 'post',
      data: formData,
      headers: { Authorization: `${this.token.token_type} ${this.token.token}` },
    });
  }

  async validateBiometrics(formData) {
    this.nativeHTTP.setDataSerializer('multipart');
    const hasPreviousSession = await this.updateAuthStateIfTokenProvided();
    return await this.nativeHTTP.sendRequest(environment.API_URL + '/validate_biometrics',{
      method: 'post',
      data: formData,
      headers: { Authorization: `${this.token.token_type} ${this.token.token}` },
    });
  }

  validateIneInformation(data){
    return this.http
      .post(environment.API_URL+'/validate_ine_information', JSON.stringify(data), this.httpOptions)
      .pipe(
        tap(response => {
          return response;
        }),
        catchError(this.handleError)
      )
  }

  async createIne(formData) {
    this.nativeHTTP.setDataSerializer('multipart');
    const hasPreviousSession = await this.updateAuthStateIfTokenProvided();
    return await this.nativeHTTP.sendRequest(environment.API_URL + '/save_ine',{
      method: 'post',
      data: formData,
      headers: { Authorization: `${this.token.token_type} ${this.token.token}` },
    });
  }

  saveSignature(data?:any) {
    return this.http.post(environment.API_URL + '/save_signature',data, this.httpOptions)
    .pipe(
      tap(response => {
        return response;
      }),
      catchError(this.handleError)
    );
  }


  hasPermission(){
    return this.http
      .get(environment.API_URL + '/has_permission', this.httpOptions)
      .pipe(
        tap(response => {
          return response;
        }),
        catchError(this.handleError)
      )
  }

  hasProductPermission(product_id){
    return this.http
      .get(environment.API_URL + '/has_product_permission/'+product_id, this.httpOptions)
      .pipe(
        tap(response => {
          return response;
        }),
        catchError(this.handleError)
      )
  }

  hasProductPermissionByName(name,data = {}){
    let parameters = '';
    Object.entries(data).forEach(([key, value]) => {
      parameters += ( this.isEmpty(parameters) ? '?' : '&' ) + `${key}=${value}`;
    });
    let endpoint = `/has_product_permission_by_name/${name}` + parameters;
    return this.http
      .get(environment.API_URL + endpoint, this.httpOptions)
      .pipe(
        tap(response => {
          return response;
        }),
        catchError(this.handleError)
      )
  }

  isEmpty(str) {
    return (!str || str.length === 0 );
  }

  // Presenta el toast con el error
  async presentToast(msg,color) {
    const toast = await this.toastController.create({
        message: msg,
        duration: 5000,
        position: 'top',
        color: color,
        cssClass: 'toast',
    });
    toast.present();
  }

    // Creación del loading
    async presentLoading() {
      this.isLoading = true;
      return await this.loadingCtrl.create({
      }).then(a => {
      a.present().then(() => {
          if (!this.isLoading) {
          a.dismiss().then(() => console.log());
          }
      });
      });
  }

  // Cierre del loading
  async dismissLoading() {
      this.isLoading = false;
      return await this.loadingCtrl.dismiss().then(() => console.log('dismissed'));
  }
  verifyUser(email){
    return this.http
      .post(environment.API_URL+'/user_exists', JSON.stringify({email}), this.httpOptions)
      .pipe(
        tap(response => {
          return response;
        }),
        catchError(this.handleError)
      )
  }
}
