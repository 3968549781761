export const environment = {
    production: false,
    API_URL: 'https://phpstack-1002212-3708399.cloudwaysapps.com/api',
    ONE_SIGNAL_APP_ID: 'ae1334d9-6e6a-43af-bc51-e4e146d2f5d4',
    FIREBASE_SENDER_ID: 'FIREBASE_SENDER_ID',
    MAPSKEY: 'pk.eyJ1IjoiamN2aXpjYWlubyIsImEiOiJja2E4Zml1NHowZTkzMnJwaWdiMHlsMjZtIn0.6ciM4BUEDiwrrJi6xqlnPg',
    SMARTLOOK_KEY: '4a0168938f55324aa5d5d67e7ad826f6066894f7',
    HELP_URL: 'https://ayuda.atmancapital.com/portal/en/home',
    API_KEY_ZOHO_ANDROID: 'NDX2fFtcVzvoel54CF33vSZuAphELpOKdqkO7wbFua3WC0vitC8%2FQ4pJWZvJMxxJ',
    API_KEY_ZOHO_IOS: 'NDX2fFtcVzvoel54CF33vSZuAphELpOKdqkO7wbFua3WC0vitC8%2FQ4pJWZvJMxxJ',
    ACCESS_TOKEN_ZOHO_ANDROID: 'harFESiTd11r4GfyQ1vxIVd4%2FVjQz8462%2FgGpF3U%2FR%2B7gdRT1%2F%2FP%2B%2BIY5z7RggTJd94%2FuVyJusH4rle7WsCgniz5siE76vjkS0lpgL7YDzexMiCP4%2BlWNA%3D%3D',
    ACCESS_TOKEN_ZOHO_IOS: 'Wt5HosGA%2FZlw1DQq1pST9Kw4ERLPt6iESkfvrMNpWoGk1b5Twgv2h8AkUZTl2IkGghDEe3lw2%2BgCEdNLolS58xkCuJ5Lfn3IY1M714YWBb4TwgDQvS50Gg%3D%3D',
    XML_BASE_URL: 'https://phpstack-1002212-3708399.cloudwaysapps.com',
    BUGSNAG_API_KEY: '9f9d87de493ca0dca8271dd656293ec3e4707915bb5c81bb505418bca3365785a8bb362a7fb9b279'
};
